<template>
  <div class="check">
    <div class="check_pc">
      <img src="/assets/icon/check/banner_pc.png" />
      <div class="checkin">
        <div class="box">
          <img src="/assets/icon/check/box.png" />
          <div class="boxin">
            <div>请到持移动设备扫描二维码确认名单！</div>
            <div class="btn" @click="handlego">
              <img src="/assets/icon/check/btn_pc.png" />
              <span>回主页</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="check_m">
      <div class="top">
        <img
          src="/assets/icon/check/banner_m.png"
          v-if="!loginWallet || !holder"
        />
        <img src="/assets/icon/check/no.png" v-else />

        <div class="topin">
          <img src="/assets/icon/check/m_logo.png" />
        </div>
      </div>
      <div class="themImg" @click="connectWallet" v-if="!loginWallet">
        <img src="/assets/icon/check/wallet.png" />
      </div>
      <div class="themImg" v-else>
        <img src="/assets/icon/check/nohave.png" v-if="!holder" />
        <img src="/assets/icon/check/have.png" v-else />
      </div>
      <div class="content">
        {{ content }}
      </div>
      <div class="address">您的钱包地址为：{{ walletAddress }}</div>
      
    </div>
    <div class="footbanner">
      <img src="/assets/icon/check/left.png" />
    </div>
  </div>
</template>

<script>
import { connectWallet, getCurrentAccount } from "@/utils/login.js";
import { activity_redeem_beer_caps } from "@/api/item/index.js";
export default {
  name: "Check",
  data() {
    return {
      walletAddress: "",
      currentAccounttext: "",
      content: "点击小狐狸喝酒！",
      loginWallet: false,
      holder: false,
    };
  },
  created() {},
  mounted() {
    this.checkLogIn();
    this.watchWeb3Event();
  },

  methods: {
    handlego() {
      this.$router.push("/");
    },
    async activity_redeem_beer_caps() {
      console.log("this.currentAccounttext===" + this.currentAccounttext);
      let params = {
        walletaddress: this.currentAccounttext,
      };
      let data = await activity_redeem_beer_caps(params);
      console.log(data);
      if (data.message == "you need register first / you have already redeemed") {
        this.holder = false;
        this.content = "您还未注册参与活动或您已经领取过瓶盖了";
      }else{
        this.holder = true;
        this.content = `恭喜您获得${data.data.beer_caps}瓶盖`;
        
      }
      
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount) {
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        this.currentAccounttext = currentAccount;
        this.loginWallet = true;
        this.walletAddress =
          currentAccount.substr(0, 4) + "***" + currentAccount.substr(-4);
        this.activity_redeem_beer_caps();
      } else {
        this.content = "点击小狐狸喝酒！";
      }
    },
    async connectWallet() {
      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
      }
      this.loginWallet = true;
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        if (res[0]) {
          if (localStorage.getItem("wallet_address") != res[0]) {
            localStorage.setItem("wallet_address", res[0]);
          }

          that.currentAccounttext = res[0];
          that.walletAddress = res[0].substr(0, 4) + "***" + res[0].substr(-4);
          that.loginWallet = true;
          that.activity_redeem_beer_caps();
        } else {
          that.loginWallet = false;
          that.holder = false;
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
            } else {
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
    },
  },
  computed: {},
};
</script>

<style src="./index.scss" lang="scss" scoped></style>


<style src="./index.mobile.scss" lang="scss" scoped></style>
